import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Appads from "./components/appads/Appads";
import appads_ from "./components/app-ads/App-ads.js";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy"; // Import the PrivacyPolicy component

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/app-ads.txt" component={Appads} />
                <Route exact path="/app-ads.txtS" component={appads_} />
                <Route exact path="/privacy" component={PrivacyPolicy} /> {/* Add the Privacy Policy route */}
            </Switch>
        </Router>
    );
}

export default App;
