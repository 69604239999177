import React from 'react';
import './world.css'



const World = () => {


    return <div id="world">
        <div className='row'>
            <div className='col app display-1'><i className="bi bi-bookmark-check"></i>  Vision</div>
        </div>
        <div className='row'>
            <div className='col-md-12 col-sm-12'>

                <video controls width="100%">
                    <source src="https://d1o140uow6h70z.cloudfront.net/out/v1/cd2319aa70fc4f23900a469fbe6cb08e/e6a9403287234ba0937316dbd68e992d/1cd4d3aaa33a452d913eed6b83e0aad5/index.m3u8"
                         />
                </video>

            </div>
        </div>

    </div>;
};

export default World;
