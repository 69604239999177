import React from 'react';
import './PrivacyPolicy.css'; // Optional: Add styles if needed

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <div className="container mt-4">
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date:</strong> January, 1st 2025</p>
                <p>Welcome to <strong>Baoba Leaders</strong> (“we,” “our,” “us”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application (“App”), website, and associated services. Please read this policy carefully to understand what information we collect and how we use it.</p>

                <h2>1. Information We Collect</h2>
                <h3>1.1. Information You Provide</h3>
                <ul>
                    <li><strong>Personal Information:</strong> Name, email address, phone number, date of birth, gender, and other identifying information you provide during registration.</li>
                    <li><strong>Business Information:</strong> Details about your business, such as name, industry, size, and location, if applicable.</li>
                    <li><strong>Financial Information:</strong> Payment details if you purchase services through the App.</li>
                </ul>
                <h3>1.2. Information Collected Automatically</h3>
                <ul>
                    <li><strong>Device Information:</strong> Device type, operating system, unique device identifiers, and IP address.</li>
                    <li><strong>Usage Data:</strong> Details about how you interact with our App, including features accessed, time spent, and actions taken.</li>
                    <li><strong>Location Data:</strong> Approximate or precise location, if enabled on your device.</li>
                </ul>
                <h3>1.3. Information from Third Parties</h3>
                <ul>
                    <li><strong>Social Media Data:</strong> If you link your social media accounts, we may receive profile information from those platforms.</li>
                    <li><strong>Third-Party Services:</strong> Data provided by third-party services such as analytics providers or payment processors.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <ul>
                    <li>Provide and improve the App and related services.</li>
                    <li>Personalize user experience and tailor content.</li>
                    <li>Facilitate account creation, registration, and user authentication.</li>
                    <li>Communicate with you, including sending notifications, updates, and marketing messages.</li>
                    <li>Process transactions and provide customer support.</li>
                    <li>Analyze usage trends to improve features and functionality.</li>
                    <li>Enforce our terms, conditions, and policies.</li>
                    <li>Comply with legal obligations.</li>
                </ul>

                <h2>3. How We Share Your Information</h2>
                <ul>
                    <li><strong>Service Providers:</strong> Third-party vendors that perform services on our behalf, such as payment processing, analytics, or email delivery.</li>
                    <li><strong>Business Partners:</strong> Entities with whom we collaborate to offer features, events, or promotions.</li>
                    <li><strong>Legal and Regulatory Authorities:</strong> When required by law, court order, or to protect our legal rights.</li>
                    <li><strong>Other Users:</strong> Limited information, such as your profile name and public posts, may be visible to other users of the App.</li>
                    <li><strong>Successors:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</li>
                </ul>

                <h2>4. Your Rights and Choices</h2>
                <h3>4.1. Managing Your Information</h3>
                <p>Update or delete your account information directly in the App settings. Adjust your preferences for receiving notifications or marketing messages.</p>
                <h3>4.2. Access and Portability</h3>
                <p>Request access to your data or a copy of your information in a portable format.</p>
                <h3>4.3. Deletion</h3>
                <p>Request that we delete your personal information, subject to legal obligations.</p>
                <h3>4.4. Opt-Out Options</h3>
                <p>Opt out of data collection for analytics or marketing by adjusting your device settings or contacting us.</p>

                <h2>5. Data Security</h2>
                <p>We implement reasonable technical, administrative, and organizational measures to protect your information against unauthorized access, loss, or misuse. However, no method of transmission or storage is 100% secure. Use the App at your own risk.</p>

                <h2>6. Retention of Data</h2>
                <p>We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or comply with legal obligations.</p>

                <h2>7. Children’s Privacy</h2>
                <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If you believe we have collected information from a child, please contact us to have it removed.</p>

                <h2>8. International Data Transfers</h2>
                <p>If you access the App from outside the United States, your information may be transferred to, stored, and processed in a different country where data protection laws may differ. By using our services, you consent to this transfer.</p>

                <h2>9. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be posted here with the updated effective date. We encourage you to review this policy periodically.</p>

                <h2>10. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                <ul>
                    <li><strong>Email:</strong> contact@thebaoba-group.com</li>
                    <li><strong>Address:</strong> 1111B S Governors Ave STE 6678, Dover, DE 19904, United States</li>
                    <li><strong>Phone:</strong> +1 (302) 310-4295</li>
                </ul>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
